import React, { useEffect } from 'react'
import { useAuth } from 'src/utils/auth'
import Loader from 'src/components/UI/Loader'
import FeedComponent from 'src/components/Feed/FeedComponent'
import nookies from 'nookies'
import { useAppDispatch } from 'src/store/store'
import { guestAccountDetailSlice } from 'src/store/slices/guestAccountDetailSlice'
// import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

const Home = () => {
  // const { isLoading, error, data } = useVisitorData({ extendedResult: true }, { immediate: true })
  const { loading }: any = useAuth()
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   if (!isLoading && data) {
  //   }
  //   if (error) {
  //     console.error('Error fetching visitor data:', error.message)
  //   }
  // }, [isLoading, data, error])

  useEffect(() => {
    const token = nookies.get()
    const openLoginCookie = token?.openLoginModal
    if (openLoginCookie) {
      nookies.set(undefined, 'redirectUrl', openLoginCookie, { path: '/' })
      openloginModal()
    }
  }, [])

  const openloginModal = () => {
    dispatch(
      guestAccountDetailSlice({
        modal: 'authModal',
        isOpen: true,
        id: { media: 'login', modalFor: 'login', startWith: 'loginModal' },
      }),
    )
    nookies.destroy(undefined, 'openLoginModal', { path: '/' })
  }
  if (loading) return <Loader />
  return <FeedComponent postData={{}} />
}

export default Home

// export async function getServerSideProps(context) {
//   const { req } = context
//   const cookies = cookie.parse(req?.headers?.cookie)
//   const token = Boolean(cookies?.token)
//   try {
//     if (token) {
//       return {
//         redirect: {
//           destination: '/feed',
//           permanent: false,
//         },
//       }
//     }
//     return {
//       props: {
//         feed: {},
//       },
//     }
//   } catch (error) {
//     console.error('Error fetching data:', error)
//     return {
//       redirect: {
//         destination: '/',
//         permanent: false,
//       },
//     }
//   }
// }
